@font-face {
  font-family: 'horev';
  src: local('horev'), url(./fonts/horev/horev/horevclm-heavy-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'Felix007';
  src: local('Felix007'), url(./fonts/felix007/fonts/felix007-medium-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'Yusei_Magic';
  src: local('Yusei_Magic'), url(./fonts/Yusei_Magic/YuseiMagic-Regular.ttf) format('truetype');
}
* {
  font-family: 'felix007';
  padding: 0;
  margin: 0;
}
:root {
  --lgreen: #6fba82; /*רקע תפריט*/
  --primaryColor: #f1bf95; /*כפתור באנר*/
  --grey: #585f5ed2; /*טקסט*/
  --secColor: #f5deb3; 
  --mainWhite: white;
  --back: #e3f8fdab; /*רקע*/
  --lightShadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.4);
  --darkShadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.5);
  --box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
}

.bf {
  padding-top: 30px;
  border-style: solid;
  border-width: 0px 28vw 99px 72vw;
  border-color: transparent transparent  var(--mainWhite) transparent;
  background:var(--back);
}
.af {
  left: 20vw;
  bottom: 45px;
  position: relative;
  border-style: solid;
  border-width: 50px 80vw 0px 20vw;
  border-color: var(--mainWhite) transparent transparent transparent;
  transform: translateX(-20%) translateY(90%);
  background:var(--back);
}
.empty-blue {
  height: 40px;
  background: var(--back);
  margin: auto;
}
.empty-white {
  height: 150px;
  margin: auto;
}

div{
  direction: rtl;
  color: var(--grey);
}

h1 {
  font-size: 2rem;
  color:var(--grey);
  font-family:'Horev';
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.3;
}
/* ירוק ממורכז*/
h2 {
  font-size: 2rem;
  color:var(--lgreen);  
  font-family:'Horev';
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.3;
}
/*ירוק ראשי לא ממורכז*/
h3 {
  padding-right: 16px;
  font-size: 2rem;
  color:var(--lgreen);  
  font-family:'Horev';
  letter-spacing: 3px;
  line-height: 1.3;
  text-align: right;
}
/* ירוק כתב אחר */
h4{
  font-size: 1.5rem;
  color:var(--lgreen);  
  letter-spacing: 3px;
  line-height: 1.3;
}
/* אפור ממורכז קטן יותר */
h5 {
  font-size: 1.9rem;
  color:var(--grey);
  font-family:'Horev';
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.3;
}
/* פסקה ממורכזת */
h6 {
  font-size: 1.4rem;
  letter-spacing: 1.7px;
  line-height: 1.4;
  margin: auto;
  width: 85%;
  padding: 5px;
  text-align: center;
  font-weight: normal;
}

p {
  text-align: justify;
  font-size: 1.4rem;
  letter-spacing: 1.7px;
  line-height: 1.6;
  /* width: 85%; */
  /* margin: auto; */
}

.services-center p {
  text-align: center;
}


/************** home-page **************/

/*hero*/
.void{
  height: 440px;
}
.curved {
  position: relative;
  background: white;
  height: 9vh;
  bottom: -30px;
  border-bottom-left-radius: 90% 30%;
  border-bottom-right-radius: 10% 50%;
  border-bottom: 4px groove var(--lgreen);
}
.pic{
  width: 96vw;
  height: 550px;
  background: url("./images/icons/newlap.png") no-repeat;
  background-position: left;
  background-size: 70%;
}
.en_pic {
    direction: ltr;
    margin-right: 30px;
    background-position: right !important;
}
/* Banner */
.banner h1 {
  padding: 30px;
  padding-bottom: 0px;
  font-size: 7rem;
  text-align: right;  
  letter-spacing: 6px;
  line-height: 0.8;
  color: var(--grey);
}
.banner {
  width: 26%;
  padding-top: 90px;
  padding-right: 2%;
}
/*hero contact form*/
.sform{
  position: absolute;
  left: 23vw;
  top: 196px;
  width: 27vw;
  text-align: center;
}
.en_sform{
  left: 49vw; 
}

.sform input{
  border: 3px solid var(--grey);
  border-radius: 5px;
  padding: 5px;
}
.sform-control {
  width: 80%;
  font-size: 1.1rem;
  padding: 3px;
  margin: 3px;
}
.cur{
  color: var(--mainWhite);
  font-size: 30px;
}
.submit{
  cursor: pointer;
  padding-top: 3px;
  margin-top: 17px;
  width: 90%;
  height: 100%;
  font-weight: 600;
  font-size: x-large;
  letter-spacing: 4px;
  color: var(--grey);
  box-shadow: var(--lightShadow);
  background: var(--primaryColor);
  border-radius: 55px 355px 55px 355px/355px 55px 355px 55px;
}
.submit:hover {
  transform: translateY(0.1rem);
  box-shadow: 1px 1px var(--lgreen);
}
.submit-error {
  margin-top: 55px;
  text-align: center;
  color: red;
}
.submit-error-form {
  text-align: center;
  color: red;
}
@media screen and (max-width: 1170px) {
  .submit-error {
    margin-top: 25px;
  }
  .void{
    height: 420px;
  }.sform{
    left: 21vw;
    top: 206px;
    width: 30vw;
  }
  .banner h1 {
    padding-top: 45px;
    font-size: 5.5rem;
  }
  .lang h1 {
    font-size: 2rem !important;
  }
  .en_sform{
    left: 47vw; 
  }
  .submit{
    width: 85%;
    height: 40px;
    margin-top: 5px;
    padding-top: 0px;
    font-size: x-large;
  }
}
@media screen and (max-width: 970px) {
  .submit-error {
    margin-top: 10px;
  }
  .lang h1 {
    padding-left: 50px;
    font-size: 1.2rem !important;
    }
  .void{
    height: 390px;
  } 
  .pic {
    margin-top: -40px;
    height: 560px;
    width: 100%;
  }
  .banner h1 {
    padding-top: 65px;
    font-size: 5rem;
  }
  .banner{
    padding-right: 10px;
  }
  .en_sform{
    left: 45vw !important; 
  }
  .sform{
    left: 18.3vw;
    top: 173px;
    width: 32vw;
  }
  .sform-control {
    margin: 2px;
  }
  .submit{
    width: 85%;
    margin-top: 4px;
    font-size: larger;
    padding-bottom: 0px;
  }
  .cur{
    font-size: 20px;
  }
}
@media screen and (max-width: 890px) {
  .submit-error {
    width: 100vw;
    margin-right: -86%;
    margin-top: 40px;
  }
  .en_pic {
    direction: rtl;
  }
  .curved {
    height: 5vh;
  }
  .void{
    height: 420px;
  } .pic {
    background-position: right;
    margin-right: 10px;
    height: 560px;
    width: 135vw;
  }
  .lang h1 {
    font-size: 2rem !important;
    line-height: 1.2 !important;
  }
  .banner h1 {
    margin-top: 90px;
    text-align: center !important;
    font-size: 3rem;
  }
  .banner {
    margin-top: -70px;
    padding-top: 0px;
    width: 70%;
  }
  .sform{
    left: 30.5vw !important;
    top: 235px;
    width: 37vw;
  }
  .submit{
    width: 95%;
    padding-top: 3px;
  }
}
@media screen and (max-width: 680px) {
  .submit-error {
    margin-right: -75%;
  }
  .lang h1 {
    line-height: 1.3 !important;
    font-size: 1.2rem !important;
  }
  .void{
    height: 350px;
  } .pic {
    margin-right: 3vw;
    height: 480px;
    width: 100wv;
  }
  .banner h1 {
    font-size: 3rem;
  }
  .sform{
    left: 28vw !important;
    top: 202px;
    width: 39vw;
  }
  .sform input{
    height: 15px;
    margin: 2px;
  }
  .submit{
    width: 85%;
    height: 38px;
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 600px) {
  .submit-error {
    margin-right: -66%;
    margin-top: 0px;
  }
  .void{
    height: 480px;
  }.pic{
    height: 650px;
    margin-right: 1%;
  }
  .sform input{
    height: 19px;
    margin: 3px;
  }
  .banner{
    padding-top: 7px;
  }
  .sform{
    width: 43vw;
    left: 27vw !important;
    top: 209px;
  }
  .submit{
    margin-top: 58px;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .void{
    height: 450px;
  }.pic{
    height: 630px;
  }.sform input{
    font-size: 16px;
    height: 12px !important;
    margin: 1px;
  } .banner{
    padding-top: 1px;
  } .en_sform{
    left: 47vw; 
  } .sform{
    top: 206px;
    margin-top: 6px;
  }
  .sform-control {
    margin: 3px !important;
  }
  .submit{
    margin-top: 53px;
    margin-right: -20px;
    width: 135%;
    height: 100%;
  }
}
@media screen and (max-width: 490px) {
  .sform{
    top:214px;
    left: 30vw !important;
  }
  .sform-control {
    border-width: 1px !important;
  }
}

/** prolog **/

.prolog{
  text-align: center;
  background: var(--back);
  margin: auto;
  padding: 80px 0px 0px 0;
}
.pro-btn-wrap {
  text-align: center;
  display: inline-block;
  margin: 15px 0 -20px 0;
}
.pro-btn {
  width: 300px !important;
  margin: 10px 15px;
  font-size: medium;
  padding: 5px 0;
  z-index: 0;
}

/** horiz form **/

.hori-form {
  text-align: center;
  display: inline-block;
  margin: auto;
  width: 95%;
  margin-right: 2%;
}
.hori-form h2 {
  padding-top: 5px;
  text-align: center !important;
}
.form-inline {  
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 15px;
    /* padding-bottom: 100px !important; */
}
.submit-hori {
  font-size: 1.4rem;
  margin-top: -10px;
}
@media screen and (max-width: 1410px) {
  .submit-hori {
    margin-top: 0px;
  }
}
@media screen and (max-width: 560px) {
  .hori-form {  
    margin: 0 13% !important;
  }
  .hori-h2 {
    width: 72%;
  }
  .submit-hori {
    width: 60% !important;
    margin: 0% 6%;
  }  
 .hori-form-mobile {
    width: 50vw !important;
    margin-right: 12% !important;
  }
}

/** puzzle **/

.puzzle{
  height: 90px;
  background-size: contain;
  position: relative;
  top: 30px;
}
.services{
  text-align: center;
  padding: 50px;
  padding-bottom: 0px;
  color: var(--grey);
  background: var(--back);
}
.services-center {
  text-align: center;
  margin: auto;
  padding: 20px;  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.services-center2 {
  text-align: center;
  margin: auto;
  padding: 0 20px;  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  /* grid-column-gap: 1rem; */
  /* grid-row-gap: 1rem; */
}
@media screen and (max-width: 480px) {
  .services{
    padding: 0px;
  }
  .services-center {
    padding: 11px;  
  }
}
.block-services {
  padding: 0.8rem 0rem;
  background: var(--mainWhite);
  box-shadow: var(--lightShadow);
  border: 1px solid var(--secColor);
  border-radius: 355px 10px 355px 10px/10px 355px 10px 355px;
}
.block-services:hover {
  box-shadow: var(--darkShadow);
}
.gif{
  height: 60px;
  width: 60px;
}
.blog-img {
  height: 260px;
  width: 260px;
}
.post {
  text-decoration: none;
}
.post h6 {
  color: var(--grey);
}

.sbf {
  background: var(--back);
  border-style: solid;
  border-width: 0px 100vw 90px 0vw;
  border-color: transparent transparent  var(--mainWhite) transparent;
}
.saf {
  background: var(--back);
  left: 20vw;
  bottom: 63px;
  position: relative;
  border-style: solid;
  border-width: 70px 0vw 0px 100vw;
  border-color: var(--mainWhite) transparent transparent transparent;
  transform: translateX(-20%) translateY(90%);
}


/************** ABOUT-page **************/

.about {
  background: var(--mainWhite);
  display: grid;
  padding: 0 40px;
  margin-top: 20px;
  grid-row-gap: 2rem;  
}
/* both text - me and choose */
.centerr{
  margin: auto;
  padding: 40px 20px !important;
}

.me {
  width: 370px;
  height: 370px;
  background-size: 105% !important;
  background-position: center 20% !important;
  box-shadow: var(--lightShadow);
  border: 10px double var(--secColor);
  margin: auto !important;
  background: url("./images/me.jpg") no-repeat;
  border-radius: 55px 355px 55px 355px/355px 55px 355px 55px;
}

/** ycube **/
.sec {
  margin: auto;
  display: grid;  
  grid-row-gap: 1rem;
  padding: 0px 10px 10px 10px !important;
  /* background-color: wheat !important; */
}
.ycube { 
  margin: auto;
}
.logoa {
    width: 40vw;
    height: 75vh;
    background: url("./images/gif/logo.gif") no-repeat;
    background-position: center !important;
}

@media screen and (min-width: 1050px) {
  .sec {
    padding: 20px 0px 20px 70px !important;
    grid-template-columns: 1fr 2fr;
  }
  .ycube {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .logoa {
    background-size: 60%;
  }
  .about {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    padding: 0px 50px;
  }
  .left{
    position: relative;
    bottom: 30px;
  }
  .choose {
    margin: auto;
    width: 370px;
    height: 370px;
    background: url("./images/icons/focus.png") no-repeat;
    background-size: contain;
    position: relative;
  }
}
@media screen and (max-width: 720px) {
  .about {
    padding-right: 10px;
    width: 93%;
  }
  .me{
    margin-top: 20px;
    width: 260px;
    height: 260px;
  }
  .ycube img{ 
    /* margin: 40px -20px 0 0 !important; */
  }
  .logoa {
    width: 95vw;
  }
  .centerr{
    padding: 10px 20px !important;
  }
}


/************** service-page **************/

.weblist{
  background: var(--mainWhite);
}
.weblist-center {
  padding-top: 20px;
  width: 80vw;
  margin: auto;
  display: grid;
}
.websec{
  margin-bottom: 20px;
  margin-right: -7px;
  padding: 0px 20px;
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); 
}
.ltr{
  direction: ltr;
}
@media screen and (max-width: 420px) {
  .websec{
    margin: auto;
    padding: 15px;
  }
  .weblist-center {
    width: 97vw;
  }
  .ltr{
    direction: rtl;
  }
}

/* רשימת תכונות אתר */
ol.gradient-list > li, ol.gradient-list > li::before {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}
ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  padding-top: 10px;
  padding: 1rem;
  border-radius: 2%;
  box-shadow: var(--box-shadow);
}
ol.gradient-list > li {
  background: white;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  padding: 13px 2.5rem 13px 8px;
  position: relative;
  min-width: 310px;
}
ol.gradient-list > li::before, ol.gradient-list > li::after {
  background: linear-gradient(215deg, rgb(173, 219, 240) 0%, var(--back) 100%);
  border-radius: 1rem 1rem 1rem 0;
  position: absolute;
  overflow: hidden;
  height: 3rem;
  right: -0.4rem;
  width: 2.5rem;
  top: -7px;
}
ol.gradient-list > li::before {
  content: counter(gradient-counter);
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  z-index: 1;
  font-size: 1.4rem;
  padding: 0.3em 0.4em;
}
ol.gradient-list > li + li {
  margin-top: 1rem;
}


/************** portfolio-page **************/

/*-------מסגרת  זזה על תמונה--------*/
.grid *,.grid *:after, .grid *:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.grid {
	position: relative;
	margin: 0 auto;
	padding: 1em 0 4em;
	list-style: none;
	text-align: center;
}
.grid figure {
	position: relative;
	float: right;
	overflow: hidden;
	margin: 10px auto;
	background: white;
	text-align: center;
  cursor: pointer;
  border-radius: 6px;
}
.grid figure img {
	position: relative;
	display: block;
	opacity: 0.8;
}
.grid figure figcaption {
	padding: 2em;
	color: #fefefe;
	text-transform: uppercase;
	font-size: 1.2em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}
.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
/***** Chico *****/
figure.effect-chico img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(1);
  max-width: 100%;
  width: 470px;
  height: 240px;
  border-radius: 5px;
  border: 1px solid var(--verylightblue);
}

@media screen and (min-width: 600px) {
  figure.effect-chico img {
    height: 240px;
  }
}

figure.effect-chico:hover img {
	opacity: 0.1;
  transform: scale(1.04);
}
figure.effect-chico a {
  text-decoration: none;
  color: var(--primaryColor);
  margin: 0 auto;
  font-size: 2rem;
  padding: 110px 0 20px 0;
  transform: scale(1.5);
}
/*המסגרת*/
figure.effect-chico figcaption::before {
	position: absolute;
	top: 15%;
	right: 10%;
	bottom: 15%;
	left: 10%;
	border: 2px solid var(--primaryColor);
	content: '';
	-webkit-transform: scale(1.5);
	transform: scale(1.1);
}
/*עושה שיעלם כשלא עוברים*/
figure.effect-chico figcaption::before,
figure.effect-chico a {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}
/*עושה שיופיע כשעוברים*/
figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover a {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

/************** cotact-page **************/

.contact-me {
  margin: auto;
  width: 260px;
  height: 270px;
  background-size: 105% !important;
  background-position: center 20% !important;
  box-shadow: var(--lightShadow);
  border: 10px double var(--secColor);
  background: url("./images/contact-me.jpg") no-repeat;
/*   background: url("./images/me.jpg") no-repeat; */
  border-radius: 35px 335px 35px 335px/335px 35px 335px 35px;
}

.col {
  display: grid;
  padding: 20px;
  padding-top: 3rem;
  /* padding-bottom: 1rem; */
  background: var(--back);
}
/*icons*/
.icon-align {
  text-align: center;
  margin-right: 0%;
}
.col a {
  font-size: 1.4rem;
  color: var(--grey);
}
.contact-icon{
  margin: 30px;
  margin-bottom: -17px;
  padding: 1px 15px 12px 5px;
  font-size: 1.4rem;
  color: #3085a3;
}
.contact-icon:hover {
  transform: scale(1.3);
}

/*contact form*/
.icon-sec, .contact-sec {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 90px !important;
}
.cform input{
  border: 0px;
  padding: 8px;
  padding-right: 20px;
  box-shadow: var(--lightShadow);
  color: var(--grey);
  border-radius: 55px 355px 55px 355px/355px 55px 355px 55px;
}
.form-control {
  width: 90%;
  font-size: 1.4rem;
  margin: 3px;
}
.long { 
  border: 2px;
  padding: 8px;
  padding-right: 20px;
  border-radius: 35px 355px 35px 355px/355px 35px 355px 35px;
  box-shadow: var(--lightShadow);
  color: var(--grey);
}
.form-submit {
  background: var(--lgreen);
  color: var(--mainWhite);
  margin: 7px 10px 0 0 !important;
  padding: 3px 15px !important;
  width: 90%;
  font-size: larger;
  height: 45px;
}

@media screen and (min-width: 1140px) {
  .col {
    grid-template-columns: 2fr 3fr !important;
    padding: 15px 0 0 !important;
    margin-bottom: -75px !important;
  }
  .icon-sec {
    width:80%;
    padding: 10px 0px;
  }
  .form-control {
    width: 85%;
  }
  .icon-align {
    text-align: right;
    margin-right: 19%;
  }
}
@media screen and (max-width: 580px) {
  .contact-sec, .icon-sec {
    text-align: center;
    width: 90%;
    margin-bottom: 40px !important;
  }
  .icon-align {
    text-align: right !important;
    margin-right: 19%;
  }
  .form-control {
    margin-right: -11px;
  }
  .s{
    font-size: large;
    margin-right: -6px !important;
  }
}
@media screen and (max-width: 400px) {
  .icon-align {
    margin-right: 1% !important;
  }
}


/*********************** Navbar ***********************/

/* שורת תפריט*/
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, var(--secColor) 95%, var(--back) 100%);
}
/* כותרת*/
.nav-header {
  display: flex;
  justify-content: space-between;
}
/*לוגו*/
.nav-logo {
  margin: 20px 10px 9px;
  height: 40px;
}

.nav-logo:hover,
.nav-logo:focus {
    box-shadow: 0 0 0.2em 0 var(--blue) ;
    transform: translateY(-0.1em);
}
/* כפתור התפריט*/
.nav-btn {
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}
/*אייקון התפריט*/
.nav-icon {
  position: absolute;
  left: 20px;
  top: 25px;
  font-size: 1.8rem;
  color:var(--grey);
}

/*** מסך קטן ***/

  /* עיצוב התפריט הנפתח */
  .links {
    background-color: var(--lgreen);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    transition: 0.5s;
    overflow-x: hidden;
    text-align:center;
    padding-top: 12vh;  /* גובה הקישורים בתפריט הקטן */
  }
    /*הכפתורים בתפריט*/
  .links a {
    color: var(--secColor);
    font-family: 'horev';
    text-decoration: none;
    letter-spacing: 3px;
    padding: 1px 30px;
    font-size: 2rem;
    font-weight: 500;
    display: block;
    line-height: 1.5;
  }
  /* nav */
.navi {overflow: hidden;}
.navi a{position:relative;}
.navi a:hover, .navi a:after {color:var(--mainWhite);}
.navi a:hover:before{opacity:1; bottom:0;}
.navi a:before {
  content:"";
  position:absolute; 
  left:35%;
  opacity:0;
  bottom:100%; 
  width:30%; 
  height:auto; 
  /* background-color:#ca9a9;  */
  border-bottom: 3px solid white;
  -o-transition:all .22s ease-out;
  transition:all .2s ease-out;
}
/* תפריט קטן, כש0 הוא סגור*/
.hide-nav {
  width: 0px;
}
.closebtn {
  color: var(--secColor);
  position: absolute ;
  cursor: pointer;
  top: 10px;
  right: 20px;
  font-size: 36px;
  margin-left: 50px;
}
.closebtn:hover{
  color: var(--mainWhite);
}

/*** מסך גדול ***/
@media screen and (min-width: 1050px) {

    /*עיצוב גובה התפריט*/
  .links {
    background-color: transparent;
    justify-content: center;
    margin-top: 10px;
    padding-top: 0;
    display: flex;
  }
  /* כפתורי תפריט */
  .links a {
    /* width: 220px; */
    font-weight: 500;
    font-size: 1.7rem;
    padding: 2px 1.5rem;
    color: var(--grey);
    text-decoration: none;
  }
  /*מעלים תפריט קטן */
    .nav-btn {
      display: none;
    }
    .closebtn {
      display: none !important;
    }
    .hide-nav {
      width: 95%;
      height: 28%;
    }
    .navi a:before {
      left:0;
      width:100%; 
      height:auto; 
    }
}


/*********** כף יד מנופפת ***********/

.float-contact {
  color:var(--secColor);
  background-color: rgba(109, 154, 180, 0.589);
  border: 2px solid var(--mainWhite);
  border-radius: 50%;
  position: fixed;
  font-size: 2rem;
  padding: 10px;
  left: 30px;
  bottom: 30px; 
  z-index: 1;
}
.float-contact:hover{
  transform: rotate(20deg);
}
.call {
  bottom: 100px;
  color: var(--lgreen);
}
.chat {
  bottom: 170px; 
  color: var(--mainWhite);
}


/*********** footer *********/

.footerWrapper{
  height: auto;
  padding: 10px 0 10px;
  background: var(--grey);
}
.footerWrapper p{
  color: var(--primaryColor);
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  direction: ltr;
}


/********** פוסט *************/
.post-cotain {
  text-align: right !important;
  margin: auto;
  padding: 20px;
  width: 80%;
}

.post-cotain p + p, li + li { 
  margin: 10px 0 10px;
}

.post-cotain li {
  text-align: justify;
  font-size: 1.4rem;
  letter-spacing: 1.7px;
  line-height: 1.6;
}
.post-cotain ol ul,
  .post-cotain ol {
    margin: 10px 0 10px;
    padding-right: 25px;
}

.post-screenshot {
  margin: 1px 0;
  width: 75%;
}
.post-screenshot-small {
  width: 35%;
}

.post-img {
  width: 35%;
  padding: 20px;
}
@media screen and (max-width: 990px) {
  .post-img {
    width: 65%;
  }
  .post-cotain {
    padding: 10px;
    width: 90%;
  }
  .post-screenshot {
    margin: 1px;
    width: 100%;
  }
}
@media screen and (max-width: 590px) {
  .post-img {
    width: 85%;
  }
  .post-screenshot-small {
    width: 65%;
  }
}



/* ====================== */
#mainnav ul {
    list-style: none;
}
#mainnav ul li:first-child {
margin: 10px;
}

#mainnav ul.submenu {
  width: 100% !important;
  background-color: var(--lgreen);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1050px) {
  ul.submenu
  {
   opacity: 1 !important;
   visibility: visible !important;
}
}

@media screen and (min-width: 1050px) {
  #mainnav ul li{
    float: right !important;
  }
  .home {
    width: 15% !important;
  }  
  #mainnav ul.submenu {
    width: 240% !important;
  }
}
#mainnav ul.submenu li:first-child {
    /* border-top: none; */
}
/* מפריד בין השורות */
#mainnav ul.submenu > li {
    /* border-top: 1px solid white; */
    float: none !important;
    margin: 0px !important;
}
/* עיצוב שורה בסאב מניו */
#mainnav ul.submenu > li > a {
    color: wheat;
}
#mainnav ul.submenu > li > a:hover {
color: white;
}
/* נראות סאבמניו */
/* .open-submenu > ul.submenu */
  #mainnav ul li:hover > ul.submenu
   {
    opacity: 1;
    visibility: visible;
}
.close-submenu > ul.submenu {
opacity: 0;
visibility: hidden;
}
.icon {
  padding-right: 9px;
}

/** המלצות **/

.carousel {
  direction: ltr !important;
  margin: auto !important;
  width: 50% !important;
  text-align: center !important;
}
.carousel h6 {
  color: var(--back);
  width: 70%;
}
.site-link {
  text-decoration: underline;
  color: var(--primaryColor);
  padding-top: 0px;
  font-size: 1.3rem !important;
  letter-spacing: 1.5px;
}
.awssld__bullets {
  transform: scale(0.6);
  direction: ltr !important;
}
.awssld__bullets button {
  margin: 0px 13px !important;
}
.awssld__content {
  border-radius: 5px;
  background-blend-mode: color-dodge;
  background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
}
.awssld__controls button {
  z-index: 0 !important;
}
.awssld {
  --organic-arrow-height: 30px !important;
  --slider-height-percentage: 55% !important;
  --control-bullet-color: grey !important;
  --control-bullet-active-color: var(--primaryColor) !important;
}
@media screen and (max-width: 1350px) {
  .carousel {
    width: 71% !important;
  }
}
@media screen and (max-width: 935px) {
  .carousel {
    width: 95% !important;
  }
  .awssld {
    --slider-height-percentage: 60% !important;
  }
}
@media screen and (max-width: 660px) {
  .carousel h6 {
    font-size: 0.9rem;
    width: 70%;
  }
  .awssld {
    --slider-height-percentage: 105% !important;
    --control-button-width: 14% !important;
    --control-bullet-color: white !important;
    --control-bullet-active-color: white !important;
  }
  .awssld__controls button {
    visibility: visible !important;
  }
}

/* modal animate logo */
.logos {
  width: 22vw;
  height: 70vh;
  background: url("./images/gif/logo.gif") no-repeat;
  background-position: center !important;
  background-size: 95%;
}

.customModal {
  margin: auto;
  background-color: wheat !important;
  padding: 1% 32% 7% !important;
}

@media screen and (max-width: 1500px) {
  .logos {
    width: 30vw !important;
    height: 74vh;
  }
}

@media screen and (max-width: 650px) {
  .logos {
    width: 88vw !important;
    background-size: 70%;
    margin-top: -100px;
  }
  .customModal {
    padding: 17% 0px !important;
  }
}

.contact-logof {
  /* margin: auto !important; */
  padding-bottom: 40px !important;
  background-color: wheat !important;
}
.logof {
  margin: auto !important;
  width: 40vw;
  height: 45vh;
  background-size: 40% !important;
  background: url("./images/gif/logo.gif") no-repeat;
  background-position: center !important;
}
@media screen and (max-width: 650px) {
  .logof {
    background-size: 122% !important;
  }
}
